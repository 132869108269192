<template>
  <el-dialog title="选择可用商品" :visible.sync="visible" width="800px" :before-close="handleClose">
    <div class="search">
      <el-input v-model="title" placeholder="搜索商品名称">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="search"></i>
      </el-input>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="goods_name" label="商品名称">
        <template v-slot="{ row }">
          <el-radio v-model="goods_id" :label="row.id">{{ row.goods_name }}</el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="goods_picture" label="商品图片" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 50px; height: 50px" :src="row.goods_picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="goods_price" label="商品价格" align="center"></el-table-column>
      <el-table-column prop="goods_stock" label="库存" align="center"></el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$parent.$parent.is_goods = !1">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() { 
    return {
      visible: !0,
      page: 1,
      rows: 5,
      total_number: 0,
      title: '',
      goods_id: '',
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    handleClose() {
      this.$parent.$parent.is_goods = !1;
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      this.$axios
        .post(this.$api.group.selectableGoods, {
          page: this.page,
          rows: this.rows,
          title: this.title,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
            this.goods_id = this.list[0].id;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    confirm() {
      this.$axios.post(this.$api.group.goodsSkuList, { group_id: this.goods_id }).then(res => {
        if (res.code == 0) {
          console.log(res);
          let info = this.list.find(item => item.id == this.goods_id);
          if (res.result.list.length) info.goods_spec = res.result.list;
          this.$emit('getGoodsInfo', info);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search .el-input {
  width: 300px;
}
.el-table {
  margin-top: 15px;
}
</style>
